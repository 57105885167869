<template>
    <div v-if="getTemplateData || showNotABot">
        <div class="main">
            <div v-if="content" v-html="content" class="placeholder-content"></div>
            <div v-else class="maintenance-body">
                <h1>Coming Soon</h1>
                <h2>We're working on our website now. Come back soon.</h2>
                <p>{{ getClient.company_name }}</p>
            </div>
        </div>

        <footer>
            <a v-if="!hideLoginLink" href="/dashboard/login?logout=true">Login</a>
        </footer>
    </div>
</template>

<script>
export default {
	name: 'webmaintenance',
	data() {
		return {
            hideLoginLink: false,
            content: ''
		}
	},
    created() {
        console.log('WebMaintenance created')
        var titleElement = document.getElementById('website_title')
        titleElement.text = 'Coming Soon'
		this.fetchPlaceholderContent();
        this.fetchPreferences();
	},
    methods: {
        fetchPreferences() {
			let preferenceCodes = ['HIDE_WEBSITE_LOGIN']
			this.apiCall({ destination: 'get_item_preference', data: { preference_code: preferenceCodes } }).then(obj => {
				this.hideLoginLink = obj.HIDE_WEBSITE_LOGIN
            })
        },
        fetchPlaceholderContent() {
            this.apiCall({ destination: 'get_placeholder_page' }).then(obj => {
                if (obj.content) {
                    this.content = obj.content;
                    this.convertOembedToIFrame()
                }
            })
        },
        convertOembedToIFrame() {
			setTimeout(() => {
				let media = document.querySelectorAll('figure.media oembed')
				media.forEach(async element => {
					let url = element.getAttribute('url')
					let api = ''
					if (url.indexOf('vimeo.com') > -1) {
						api = 'https://vimeo.com/api/oembed.json?url='
					} else {
						api = 'https://www.youtube.com/oembed?url='
					}
					await fetch(api + url).then(response => {
						return response.json()
					}).then(obj => {
						let iframe = new DOMParser().parseFromString(obj.html, "text/html").body.firstElementChild
						let ratio = obj.width / obj.height
						iframe.width = 640
						iframe.height = 640 / ratio
						element.parentNode.replaceChild(iframe, element)
					})
				});
			}, 500)
		}
    }
}

</script>

<style lang="scss">

.maintenance-body {
    margin: 3em 2em;
    text-align: center;
}

.placeholder-content {
    iframe {
        max-width: 100%;
        max-height: 100%;
    }
    @media (max-width: 768px) {
        iframe {
            height: auto;
        }
    }
}

footer {
    position: absolute;
    width: 100vw;
    bottom: 0;
    text-align: right;
}

</style>